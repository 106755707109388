import './App.css'
import Lottie from 'react-lottie';
import gears from './assets/animations/73116-clock-gear-working-man.json'
import night from './assets/animations/80381-stars.json'
import { useState } from 'react';
import useWindowDimensions from './components/screenSize';

function App() {

  const { height, width } = useWindowDimensions();

  const [open, setOpen] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)

  const gearsOptions = {
    loop: true,
    autoplay: true,
    animationData: gears,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const nightOptions = {
    loop: true,
    autoplay: true,
    animationData: night,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  function detectLeftButton(evt) {
    evt = evt || window.event;
    if ("buttons" in evt) {
      return evt.buttons == 1;
    }
    var button = evt.which || evt.button;
    return button == 1;
  }

  const handleClose = (e) => {
    open &&
      setOpen(false);

    contactOpen &&
      setContactOpen(false);

  }


  return (
    <div onClick={handleClose}>
      <div className='background'>
        <Lottie
          isClickToPauseDisabled
          options={nightOptions}
          width={'100%'}
          height={'100%'}
        />
      </div>
      <div className='centered'>
        <div>
          {
            width > 850
              ?
              <Lottie
                isClickToPauseDisabled
                options={gearsOptions}
                width={'50%'}
                height={'50%'}
              />
              :
              width > 750
                ?
                <Lottie
                  isClickToPauseDisabled
                  options={gearsOptions}
                  width={'80%'}
                  height={'80%'}
                />
                :
                <Lottie
                  isClickToPauseDisabled
                  options={gearsOptions}
                />
          }
          <div className='centerText'>
            inspirise.com is under development
          </div>
          <div className='centerText1'>
            sugar, spice, and everything nice...
          </div>
          <div className='centerText2'>
            Chemical X ?
          </div>
        </div>
      </div>
      <div className='footerLeft'>
        {
          contactOpen
          &&
          <div className='dropUp'>
            sharan@inspirise.com
          </div>
        }
        <div
          className='developerText'
          onClick={() => setContactOpen(true)}>
          Contact
        </div>
      </div>
      <div className='footerRight'>
        {
          open
          &&
          <div className='dropUp'>
            <a href='https://lottiefiles.com/w2iwhfyf7g' target="_blank">
              Star background
            </a>
            <br />
            <br />
            <a href='https://lottiefiles.com/sb8aspx4kp' target="_blank">
              Typing foreground
            </a>
          </div>
        }
        <div
          className='attributionText'
          onClick={() => setOpen(true)}>
          Attribution
        </div>
      </div>
    </div>
  );
}

export default App;
